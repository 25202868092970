(() => {

  /**
   * Extract numbers from given string
   * @param {string} value
   */
  const extractNumbers = value => value.replace(/\D/g, '')

  /**
   * Test if given value contains only the same digit
   * @param {string} value
   */
  const areAllNumbersEquals = value => {
    for (let index = 0; index < value.length; index++) {
      if (isset(value[index + 1]) && value[index] !== value[index + 1]) {
        return false
      }
    }

    return true
  }

  /**
   * Add supplemental form validations
   */

  window.Validation.add('validate-email-exist', 'Email já cadastrado', function (value) {
    let status = false

    const request = new Ajax.Request('/onestepcheckout/ajax/check_email/', {
      method: 'post',
      asynchronous: false,
      parameters: `email=${encodeURIComponent(value)}`,
      onSuccess(response) {
        status = JSON.parse(response.responseText).result === 'clean'
      },
      onComplete() {
        if ($('advice-validate-email-exist-email')) {
          $('advice-validate-email-exist-email').remove()
        }
      }
    })

    return status
  })

  const addValidations = () => {
    window.Validation.add('validate-taxvat-exist', 'CPF/CNPJ já cadastrado', function (value) {
      let status = false

      const request = new Ajax.Request('/onestepcheckout/ajax/check_taxvat/', {
        method: 'post',
        asynchronous: false,
        parameters: `taxvat=${encodeURIComponent(value)}`,
        onSuccess (response) {
          status = JSON.parse(response.responseText).result === 'clean'
        },
        onComplete () {
          if ($('advice-validate-taxvat-taxvat')) {
            $('advice-validate-taxvat-taxvat').remove()
          }
        }
      })

      return status
    })

    window.Validation.add('validate-taxvat', 'CPF/CNPJ inválido', function (value) {
      const valueNumbers = extractNumbers(value)

      if (areAllNumbersEquals(valueNumbers)) {
        return false
      }

      const validateCNPJ = cnpj => {
        if (cnpj.length != 14) {
          return false
        }

        let size = cnpj.length - 2
        let numbers = cnpj.substring(0, size)
        let digits = cnpj.substring(size)
        let sum = 0
        let position = size - 7

        for (let i = size; i >= 1; i--) {
          sum += numbers.charAt(size - i) * position--

          if (position < 2) {
            position = 9
          }
        }

        let resultado = sum % 11 < 2 ? 0 : 11 - sum % 11

        if (resultado != digits.charAt(0)) {
          return false
        }

        size = size + 1
        numbers = cnpj.substring(0, size)
        sum = 0
        position = size - 7

        for (let i = size; i >= 1; i--) {
          sum += numbers.charAt(size - i) * position--
          if (position < 2) { position = 9 }
        }

        resultado = sum % 11 < 2 ? 0 : 11 - sum % 11

        if (resultado != digits.charAt(1)) {
          return false
        }

        return true
      }

      const validateCPF = cpf => {
        let numbers, digits, sum, i, result

        if (cpf.length !== 11) {
          return false
        }

        numbers = cpf.substring(0, 9)
        digits = cpf.substring(9)
        sum = 0

        for (i = 10; i > 1; i--) {
          sum += numbers.charAt(10 - i) * i
        }

        result = sum % 11 < 2 ? 0 : 11 - sum % 11

        if (result != digits.charAt(0)) {
          return false
        }

        numbers = cpf.substring(0, 10)
        sum = 0

        for (i = 11; i > 1; i--) {
          sum += numbers.charAt(11 - i) * i
        }

        result = sum % 11 < 2 ? 0 : 11 - sum % 11

        if (result != digits.charAt(1)) {
          return false
        }

        return true
      }

      if (value.length > 14) {
        return validateCNPJ(valueNumbers)
      }

      return validateCPF(valueNumbers)
    })
  }

  // On load events
  window.addEventListener('load', addValidations)
})()
